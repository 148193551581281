<template>
	<div>
	
		<template>
			<div id="breadcrumb-alignment" class="mb-1">
				<div class="d-flex justify-content-start breadcrumb-wrapper">
					<b-breadcrumb :items="breadCrumb()"/>
				</div>
			</div>
		</template>

		<b-row class="match-height">
			<b-col lg="12" md="12">
				<b-card title="Add Role Permission">
					
					<b-alert v-model="showDismissibleAlert" variant="danger" dismissible class="mb-1">
						<div class="alert-body"> {{error_message}} </div>
					</b-alert>
				
					<b-form @submit="formSubmit">
						<b-row>
							<b-col md="12">
								<b-row>

									<b-col md="6" class="mb-2">
										<b-form-group label="Client" class="required" >
											<b-form-select v-model="form.organization">
												<b-form-select-option value="">Select Client</b-form-select-option>
												<b-form-select-option :value="organization._id" v-for="(organization, ind) in organizations" :key="ind">{{organization.organization_name | capitalize}}</b-form-select-option>
											</b-form-select>
										</b-form-group>
									</b-col>

									<b-col md="6" class="mb-2">
										<b-form-group label="Role" class="required" >
											<b-form-select v-model="form.role" @change="getMenus(); select = '';">
												<b-form-select-option value="">Select</b-form-select-option>
												<b-form-select-option :value="role.role" v-for="role in roles" :key="role._id">{{role.name}}</b-form-select-option>
											</b-form-select>
										</b-form-group>
									</b-col>
									
								</b-row>

								<b-row>
									<b-col md="12">

										<div class="inner_dv" v-if="this.form.role != '' && this.form.role != 'attendance' && this.form.role != 'panel'">
											<legend class="legend_upper_border" style="width: 200px">MENU PERMISSION</legend>
											<div class="legen_inc_div">  

												<b-row v-if="form.menus && form.menus.length > 0">

													<b-col md="8">
													<b-form-checkbox class="custom-control-warning" @change="selectAllMenu" v-model="select">Select All </b-form-checkbox>
														<hr>
															<div v-for="(menu, index) in form.menus" :key="index">
																<div>
																	<b-form-checkbox class="custom-control-warning mt-1" v-model="menu.access" @change="toggleChild(menu)">{{menu.title}}</b-form-checkbox>
																	
																	<!-- level 1 menu -->
																	<div class="pl-2 row" v-if="menu.level == 1">
																		<div class="col-4 pt-1" v-for="(permission, ip) in menu.permissions" :key="ip">
																			<b-form-checkbox v-model="permission.access" @change="toggleParent(menu)">{{permission.label}}</b-form-checkbox>
																		</div>
																	</div>

																	<!-- level 2 menu -->
																	<div v-if="menu.level == 2">
																		<div v-for="(child, ch) in menu.children" :key="ch">
																			
																			<div class="pl-2">
																				<div>
																					<b-form-checkbox class="mt-2" v-model="child.access" @change="toggleChild(child); toggleParent(menu)">{{child.title}}</b-form-checkbox>
																					<div class="pl-2 row">
																						<div class="col-4 pt-1" v-for="(permission, ip) in child.permissions" :key="ip">
																							<b-form-checkbox v-model="permission.access" @change="toggleParent(child); toggleParent2(menu);">{{permission.label}}</b-form-checkbox>
																						</div>
																					</div>
																				</div>
																			</div>
																			
																		</div>
																	</div>

																	<!-- level 3 menu -->
																	<div v-if="menu.level == 3">
																		<div v-for="(child, ch) in menu.children" :key="ch">
																			
																			<div class="pl-2">
																				<div>
																					<b-form-checkbox class="mt-2" v-model="child.access" @change="toggleChild(child); toggleParent(menu)">{{child.title}}</b-form-checkbox>
																					
																					<!-- having permission -->
																					<div class="pl-2 row" v-if="child.permissions">
																						<div class="col-4 pt-1" v-for="(permission, ipp) in child.permissions" :key="ipp">
																							<b-form-checkbox v-model="permission.access" @change="toggleParent(child); toggleParent2(menu);">{{permission.label}}</b-form-checkbox>
																						</div>
																					</div>
																					
																					<!-- having child -->
																					<div class="pl-2 row" v-if="child.children">
																						<div class="col-12 pt-1" v-for="(ch, ic) in child.children" :key="ic">
																							<b-form-checkbox v-model="ch.access" @change="toggleParent(child); toggleParent(menu); toggleChild(ch)">{{ch.title}}</b-form-checkbox>
																							<!-- permissions of child -->
																							<div class="pl-2 row" v-if="ch.permissions">
																								<div class="col-4 pt-1" v-for="(pm, ipc) in ch.permissions" :key="ipc">
																									<b-form-checkbox class="custom-control-primary" v-model="pm.access" @change="toggleParent(ch); toggleParent(child); toggleParent(menu); toggleParent2(ch); toggleParent2(child); toggleParent2(menu);">{{pm.label}}</b-form-checkbox>
																								</div>
																							</div>

																						</div>
																					</div>

																				</div>
																			</div>
																			
																		</div>
																	</div>

																	<hr>
																</div>
															</div>

													</b-col>
												</b-row>
											</div>
										</div>

									</b-col>
								</b-row>
								
								<b-row>
									<b-col>
										<b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="warning" class="mr-1"> Submit </b-button>
										<b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" @click="$router.push({ name: 'role-menu'})"> Cancel </b-button>
									</b-col>
								</b-row>
							</b-col>
						</b-row>

					</b-form>

				</b-card>
			</b-col>
		</b-row>

	</div>
</template>

<script>

import {
  BBreadcrumb, BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BAlert,BFormSelect,BFormSelectOption,BFormText,BInputGroupPrepend,BInputGroup,BMedia,BAvatar,BTable,BModal, BFormRadio,BFormTimepicker
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { GET_API, POST_API } from "../../../store/actions.type"
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';



export default {
	components: { BAlert, BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BFormSelect,
		BFormSelectOption, BFormText, BInputGroupPrepend, BInputGroup, BMedia, BAvatar, BTable, BModal, VueCropper,
		BFormRadio, BFormTimepicker, BBreadcrumb
	},

	directives: { Ripple },

	data() {
		return {
			error_message:null,
			showDismissibleAlert:false,
			form :{
				organization: '',
				role : '',
				menus : []
			},
			organizations: [],
			roles: [],
			select: ''
		}
	},

  	methods : {
		formSubmit(e){
			e.preventDefault();
			return this.$store.dispatch(POST_API, {
				data:{
					items:this.form
				},
				api: '/api/add-role-menu'
			})
			.then(() => {
				if (this.$store.getters.containsErrors) {
					this.error_message = this.$store.getters.getErrors;
					this.showDismissibleAlert = true;
					window.scrollTo(0,0);
				} else {
					this.showDismissibleAlert = false;
					this.successAlert().then(() => {
						this.$router.push({ name: 'role-menu'});
					});
					
				}
			});      
		},

		getRoles(){
			return this.$store.dispatch(POST_API, {
				data:{
					role_type:['admin', 'administrator', 'admin', 'site_client', 'site_manager', 'operation_manager', 'supervisor', 'crew']
				},
				api: '/api/get-roles-by-type'
			})
			.then(() => {
				if (this.$store.getters.containsErrors) {
					this.error_message = this.$store.getters.getErrors;
				} else {
					var data = this.$store.getters.getResults.data;
					this.roles = data;
					return this.roles;
				}
			});
		},

		getOrganizations(){
			return this.$store.dispatch(POST_API, {
				data:{},
				api: '/api/all-client-list'
			})
			.then(() => {
				if (this.$store.getters.containsErrors) {
					this.error_message = this.$store.getters.getErrors;
				} else {
					var data = this.$store.getters.getResults.data;
					this.organizations = data;
					return this.organizations;
				}
			});
		},

		getMenus(){
			return this.$store.dispatch(POST_API, {
			data:{
				user_type : this.form.role,
			},
			api: '/api/get-menus-admin'
			})
			.then(() => {
				if (this.$store.getters.containsErrors) {
					this.error_message = this.$store.getters.getErrors;
					this.showDismissibleAlert = true;
					window.scrollTo(0,0);
				} else {
					this.showDismissibleAlert = false;
					this.form.menus = this.$store.getters.getResults.data;
				}
			}); 
		},

		toggleChild(permissions){      
			if(permissions.permissions){
				this.forParent(permissions.permissions, permissions.access);
			} else {
				this.forChild(permissions.children, permissions.access);
			}
		},

		forParent(menu, access){
			menu.forEach(function(item){
				item.access = access;
			}); 
		},

		forChild(menu, access){
			menu.forEach(function(item){
				item.access = access;
				if(item.permissions){
					item.permissions.forEach(function(inner_item){
						inner_item.access = access;
					}); 
				} else {
					item.children.forEach(function(ch){
						ch.access = access;
						ch.permissions.forEach(function(sci){
							sci.access = access;
						}); 
					});
				}
			});
		},
			
		forChildL3(menu, access){
			menu.forEach(function(item){
				item.access = access;

				if(item.permissions){
					item.permissions.forEach(function(pm){
						pm.access = access;
					}); 
				} else {
					item.children.forEach(function(ch){
						ch.access = access;
						ch.permissions.forEach(function(inner_item){
							inner_item.access = access;
						}); 
					});
				}
				
			});
		},

		toggleParent(menu){
			var flag = false;

			if(menu.permissions){
				menu.permissions.forEach(item => {
					if(item.access == true){
						flag = true;
					}
				});
			} else {
				menu.children.forEach(item => {
					if(item.access == true){
						flag = true;
					}
				});
			}
			menu.access = flag;
		},

		toggleParent2(menu,){
			var flag = false;
			menu.children.forEach(item => {
				if(item.permissions){
					item.permissions.forEach(elem => {
						if(elem.access == true){
							flag = true;
						}
					});
				} 
				else if(item.children){

					item.children.forEach(ch => {
						if(ch.permissions){

							ch.permissions.forEach(sch => {
								if(sch.access == true){
									flag = true;
								}
							})

						}
					})

				}

			});
			menu.access = flag;
		},

		selectAllMenu(e){
			if(this.form.menus){
				this.form.menus.forEach(menu => {
					if(menu.level == 1){
						this.forParent(menu.permissions, e);
					} 
					else if (menu.level == 2) {
						this.forChild(menu.children, e);
					}
					else if (menu.level == 3) {
						this.forChildL3(menu.children, e);
					}
					menu.access = e;
				});
			}
		},

		breadCrumb(){
			var item = [{
				to:{name:'admin-dashboard'},
				text: 'Dashboard',
			},{
				to:{name:'role-menu'},
				text: 'Role Permission',
			},{
				to:null,
				text: 'Add Role Permission',
				active:true
			}];
			return item;
		},

  	},

	mounted(){
		this.getOrganizations();
		this.getRoles();
	}
}
</script>
